import React from 'react'
import Layout from "../../../components/App/Layout"
import Navbar from "../../../components/App/Navbar"
import ContentBanner from '../../../components/Common/ContentBanner'
import Footer from "../../../components/App/Footer"
import ExampleDetail from '../../../components/Examples/ExampleDetail'
import ProjectStartArea from '../../../components/Common/StartProject';
import SEO from '../../../components/App/SEO';
import exampleMetaImage from '../../../assets/images/examples/widgets/keep-follower.jpg';
import Constants from '../../../constatns'
import PlatformExample from '../../../components/Examples/PlarformExample';

// Functional component
const Examples = () => {

  return (
	<Layout>
		<SEO
		  title="Instagram合言葉キャンペーン"
		  desc="このサンプルはInstagramで合言葉キャンペーンを行う方法です。合言葉キャンペーンはストーリーズを使用してフォロー解除率を抑制させると同時に、御社のアカウントのストーリーを上位表示させる効果があります。"
		  banner={exampleMetaImage}
		  pathname={'/examples/instagram/password'}
		  type={'Article'}
		  shortName="Instagram合言葉キャンペーン"
		  createdAt="2021-11-27"
		/>
	  <Navbar />
	  <ContentBanner
		pageTitle="Instagram合言葉キャンペーン"
		homePageText="Home"
		homePageUrl="/"
		activePageText="Instagram合言葉キャンペーン"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		},{
		  url: '/examples/instagram',
		  name: 'Instagramキャンペーン'
		}]}
	  />
	  <ExampleDetail
		title={"Instagram合言葉キャンペーン"}
		description={<>このサンプルはInstagramで合言葉キャンペーンを行う方法です。<br/>合言葉キャンペーンはストーリーズを使用してフォロー解除率を抑制させると同時に、御社のアカウントのストーリーを上位表示させる効果があります。<br/>このサンプルにTwitterのリツイートやいいねどの拡散する応募方法を追加して、拡散力を追加することも効果的です。</>}
		effects={[Constants.CAMPAIGN_EFFECTS.GET_EMAILS,Constants.CAMPAIGN_EFFECTS.GET_SNS_ACCOUNTS, Constants.CAMPAIGN_EFFECTS.REDUCE_UN_FOLLOW
		  , Constants.CAMPAIGN_EFFECTS.INCREASED_ENGAGEMENT, Constants.CAMPAIGN_EFFECTS.CONNECT_WATCHING, Constants.CAMPAIGN_EFFECTS.GET_FOLLOWERS
		  , Constants.CAMPAIGN_EFFECTS.GET_HIGH_SPREAD, Constants.CAMPAIGN_EFFECTS.RETENTION,Constants.CAMPAIGN_EFFECTS.GAIN_RECOGNITION]}
		plan={'ベーシックプラン以上'}
		promotionId="197e74cafda56d82c9"
		platformExample={
		  <PlatformExample
			title="その他のInstagramキャンペーン"
			types={['instagramFollowLike', 'instagramFollowRepost', 'instagramFollowHashtag', 'instagramShare', 'instagramPhotoContest']}
		  />
		}
	  />
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples